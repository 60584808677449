import { webService } from "./services";

/* CASOS */
export async function getCasos() {
  const query = `{
        posts(where: {categoryName: "Casos"}) {
          nodes {
            slug
            caso_titulo_subtitulo {
              subtitulo
              titulo
            }
            blocksJSON
            id
            featuredImage {
              node {
                sourceUrl
              }
            }
          }
        }
      }`;
  var response = [];
  await webService.post(query).then((res) => {
    response = res.posts.nodes;
  });
  return response;
}

export async function getCaso(slugCaso) {
  const query = `{
        postBy(slug: "${slugCaso}") {
                slug
                caso_titulo_subtitulo {
                    subtitulo
                    titulo
                }
                blocksJSON
                id
                featuredImage {
                    node {
                        sourceUrl
                    }
                }
            }
        }`;
  var response = [];
  await webService.post(query).then((res) => {
    response = res.postBy;
  });
  return response;
}

/* PROYECTOS */
export async function getProyectos() {
  const query = `{
        posts(where: {categoryName: "Proyectos"}) {
          nodes {
            slug
            titulosProyectos {
              subtitulo
              titulo
            }
            blocksJSON
            id
            featuredImage {
              node {
                sourceUrl
              }
            }
          }
        }
      }`;
  var response = [];
  await webService.post(query).then((res) => {
    response = res.posts.nodes;
  });
  return response;
}
export async function getProyecto(slugProyecto) {
  const query = `{
        postBy(slug: "${slugProyecto}") {
                slug
                titulosProyectos {
                    subtitulo
                    titulo
                }
                blocksJSON
                id
                featuredImage {
                    node {
                        sourceUrl
                    }
                }
            }
        }`;
  var response = [];
  await webService.post(query).then((res) => {
    response = res.postBy;
  });
  return response;
}

/* NOTICIAS */
export async function getNoticias() {
  const query = `{
        posts(where: {categoryName: "Noticias"}) {
          nodes {
            date
            slug
            titulosNoticias {
              subtitulo
              titulo
              descripcionBreve
            }
            blocksJSON
            id
            featuredImage {
              node {
                sourceUrl
              }
            }            
          }
        }
      }`;
  var response = [];
  await webService.post(query).then((res) => {
    response = res.posts.nodes;
  });
  return response;
}
export async function getNoticia(slugNoticia) {
  const query = `{
        postBy(slug: "${slugNoticia}") {
                date
                slug
                titulosNoticias {
                    subtitulo
                    titulo                    
                }
                blocksJSON
                id
                featuredImage {
                    node {
                        sourceUrl
                    }
                }
            }
        }`;
  var response = [];
  await webService.post(query).then((res) => {
    response = res.postBy;
  });
  return response;
}

/* INTRO HOME */
export async function getHome() {
  const query = `{
        pageBy(id: "cG9zdDo1") {
          home {
            textoIntro
          }
        }
      }`;
  var response = [];
  await webService.post(query).then((res) => {
    response = res.pageBy.home.textoIntro;
  });
  return response;
}

/* QUIENES SOMOS */
export async function getQuienesSomos() {
  const query = `{
        pageBy(id: "cG9zdDoxMjM=") {
          id
          title
          blocksJSON
          featuredImage {
                node {
                    sourceUrl
                }
           }
        }
      }`;
  var response = [];
  await webService.post(query).then((res) => {
    response = res.pageBy;
  });
  return response;
}

/* PAGINA PROYECTOS */
export async function getPaginaProyectos() {
  const query = `{
        pageBy(id: "cG9zdDoxMjY=") {
          id
          title          
          featuredImage {
                node {
                    sourceUrl
                }
           }
        }
      }`;
  var response = [];
  await webService.post(query).then((res) => {
    response = res.pageBy;
  });
  return response;
}

/* PAGINA CASOS */
export async function getPaginaCasos() {
  const query = `{
        pageBy(id: "cG9zdDoxMjg=") {
          id
          title          
          featuredImage {
                node {
                    sourceUrl
                }
           }
        }
      }`;
  var response = [];
  await webService.post(query).then((res) => {
    response = res.pageBy;
  });
  return response;
}

/* PAGINA NOTICIAS */
export async function getPaginaNoticias() {
  const query = `{
        pageBy(id: "cG9zdDoxMzA=") {
          id
          title          
          featuredImage {
                node {
                    sourceUrl
                }
           }
        }
      }`;
  var response = [];
  await webService.post(query).then((res) => {
    response = res.pageBy;
  });
  return response;
}

/* PAGINA DENUNCIAS */
export async function getPaginaDenuncias() {
  const query = `{
        pageBy(id: "cG9zdDoxNjk=") {
          id
          title          
          featuredImage {
                node {
                    sourceUrl
                }
           }
        }
      }`;
  var response = [];
  await webService.post(query).then((res) => {
    response = res.pageBy;
  });
  return response;
}

/* POLITICA PRIVACIDAD */
export async function getPoliticaPrivacidad() {
  const query = `{
        pageBy(id: "cG9zdDoz") {
          id
          title
          blocksJSON
          featuredImage {
                node {
                    sourceUrl
                }
           }
        }
      }`;
  var response = [];
  await webService.post(query).then((res) => {
    response = res.pageBy;
  });
  return response;
}
