import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import trianguloCasos from '../img/triangle-caso-chico.png'

export default function ListaCasos(props) {
    const navigate = useNavigate();

    function goCaso(i, url) {        
        navigate(url, {
            state: {
                lista: props.dataCasos,
                caso: props.dataCasos[i]
            }
        })
    }

    return (
        props.dataCasos && <div className="lista-casos flex-column">
            {props.titulo && <h2>{props.titulo}</h2>}
            {/* {props.titulo && <hr />} */}
            <div className="elem-lista flex-row">
                {props.dataCasos.slice(0, props.amountItems != null ? props.amountItems : props.dataCasos.length).map((caso, i) => (
                    <button className="box-shadow" onClick={() => goCaso(i, `/casos/${caso.slug}`)} key={caso.id}>
                        <div className="elemento-casos flex-column" style={{ backgroundImage: `url(${caso.featuredImage != null ? caso.featuredImage.node.sourceUrl : ''})` }}>
                            <img src={trianguloCasos} alt="" />
                            {<h3>{caso.caso_titulo_subtitulo.titulo}</h3>}                            
                        </div>
                        {caso.caso_titulo_subtitulo.subtitulo && <div className="descripcion-breve align-center justify-center">
                            <h4>{caso.caso_titulo_subtitulo.subtitulo}</h4>
                        </div>}
                    </button>
                ))}
            </div>
        </div>
    )
}