import React, { useState, useEffect } from "react";
import { PuffLoader } from "react-spinners";
import { getPaginaDenuncias } from "../api/actions";
import FormDenuncias from "../componentes-generales/FormDenuncia";
import FormDenunciasArtistas from "../componentes-generales/FormDenunciaArtistas";
import { cacheImages } from "../functionalities/imgCache";

export default function Denuncias(props) {
  const [titulo, setTitulo] = useState("");
  const [featuredImage, setFeaturedImage] = useState("");
  const [form1, setForm1] = useState(true);

  useEffect(() => {
    getDataPagina();
    document.title = props.title;
  }, [props]);

  async function getDataPagina() {
    let titulo = "";
    let featuredImage = "";
    let response = [];
    await getPaginaDenuncias().then((res) => {
      response.push(res);
      titulo = res.title;
      featuredImage = res.featuredImage.node.sourceUrl;
    });
    await cacheImages(response);
    setTitulo(titulo);
    setFeaturedImage(featuredImage);
  }

  function elegirFormulario() {
    setForm1(!form1);
  }

  return titulo !== "" ? (
    <section className="post">
      <div
        className="post-header flex-column justify-center box-shadow"
        style={{
          backgroundImage: `url(${featuredImage != null ? featuredImage : ""})`,
        }}
      >
        {titulo && <h1 className="text-shadow">{titulo}</h1>}
      </div>
      <div className="formularios-denuncias flex-column">
        <div className="seleccion-formulario flex-row justify-center">
          <button
            className={form1 ? "active" : ""}
            onClick={() => elegirFormulario()}
          >
            Denuncia de vulneración de DDHH
          </button>
          <button
            className={!form1 ? "active" : ""}
            onClick={() => elegirFormulario()}
          >
            Protección a Artistas en Riesgo
          </button>
        </div>
        {form1 ? <FormDenuncias /> : <FormDenunciasArtistas />}
      </div>
    </section>
  ) : (
    <div className="post-loader">
      <PuffLoader color={"#20222C"} loading={true} size={150} />
    </div>
  );
}
