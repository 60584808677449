import React, { useState, useReducer } from 'react';
import { postForm } from '../api/services';

const formReducer = (state, event) => {
    return {
        ...state,
        [event.name]: event.value
    }
}


export default function FormDenuncias() {
    let initFormData = {
        'yourname': '',
        'email': '',
        'fecha': '',
        'hora': '',
        'hechos': '',
        'nombrevictima': '',
        'nombreagresor': '',
        'lugar': ''        
    }
    const [formData, setFormData] = useReducer(formReducer, initFormData)
    const [textareaSize, setTextareaSize] = useState(false)
    const [enviado, setEnviado] = useState(false);
    const [file, setFile] = useState(null);

    function getData(payload) {
        console.log(formData)
        const localFormData = new FormData();
        Object.entries(payload).forEach(([key, value]) => {            
            localFormData.append(key, value);
            console.log(localFormData)
        });
        localFormData.append(
            "archivo",
            file,
            file.name
          );
          for(var pair of localFormData.entries()) {
            console.log(`${pair[0]}: ${pair[1]}`);
          }
        return localFormData;
    }

    async function postFormLocal() {   
        let localFormData = formData                 
        localFormData.archivo = file
        await postForm(localFormData).then(res => {
            if (res.status != 'mail_sent') {
                alert(res.message)
            } else {
                setEnviado(true)
            }
        })
    }

    const handleChange = event => {    
        let value = event.target.value    
        let name = event.target.name
        let validatedValue = formData[event.target.name];        
        if ((name === "yourname" || name == 'nombreagresor' || name == 'nombrevictima') && (/^([a-zA-Z '´ñÑÀ-ú]*)$/.test(value))) {
            validatedValue = value            
        } else if ((name == 'hechos' || name == "lugar") && (/^([A-Za-z0-9 .,´ñÑÀ-ú()]*)$/.test(value))) {
            validatedValue = value   
        } else if (name == 'fecha' || name === "email") {
            validatedValue = value  
        } else if (name == "hora" && (/^([pma0-9.,:]*)$/.test(value))) {
            validatedValue = value  
        }
        setFormData({
            name: event.target.name,
            value: validatedValue,
        });    
      }

      const handleFile = event => {      
          console.log(event.target.files[0])    
          setFile(event.target.files[0])                    
      }

      function textareaToggle() {     
        if (formData.hechos == '') {
            setTextareaSize(!textareaSize)
        }
        
      }

    return (
        !enviado ? <form className="flex-column box-shadow align-end">
            <div className="quien-denuncia">
                <h2>¿Quién denuncia?</h2>
                <label className="flex-column">
                    Nombre<br />
                    <input value={formData.yourname} onChange={handleChange} name="yourname" type="text" />
                </label>
                <label className="flex-column">
                    Mail<br />
                    <input value={formData.email} onChange={handleChange}  type="email" name="email" id="" />
                </label>
            </div>
            <div className="que-paso">
                <h2>¿Qué pasó?</h2>
                <label className="flex-column">
                    Descripción de los hechos<br />
                    <textarea onFocus={() => textareaToggle()} onBlur={() => textareaToggle()} className={!textareaSize ? '' : 'textarea-big'} value={formData.hechos} onChange={handleChange} cols="40" rows="10" minLength="100" type="text" name="hechos" id="" />
                </label>
                <label className="flex-column">
                    Fecha<br />
                    <input value={formData.fecha} onChange={handleChange} type="date" name="fecha" id="" />
                </label>
                <label className="flex-column">
                    Hora<br />
                    <input value={formData.hora} onChange={handleChange} type="text" name="hora" id="" />
                </label>
                <label className="flex-column">
                    Nombre de la víctima<br />
                    <input value={formData.nombrevictima} onChange={handleChange} type="text" name="nombrevictima" id="" />
                </label>
                <label className="flex-column">
                    Nombre del agresor<br />
                    <input value={formData.nombreagresor} onChange={handleChange} type="text" name="nombreagresor" id="" />
                </label>
                <label className="flex-column">
                    Lugar donde ocurrió<br />
                    <input value={formData.lugar} onChange={handleChange} type="text" name="lugar" id="" />
                </label>
                <label className="flex-column">
                    Evidencia<br />
                    <input /* value={formData.lugar} */ onChange={handleFile} type="file" id="" />
                </label>
            </div>
            <button type="button" onClick={() => postFormLocal()}>Enviar</button>
        </form> : <div className="flex-row justify-center align-center">
            Gracias, recibimos tu denuncia.
        </div>
    )
}