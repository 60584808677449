import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { getCaso, getCasos } from '../api/actions';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import PuffLoader from "react-spinners/PuffLoader";
import ListaCasos from '../componentes-generales/ListaCasos';
import { cacheImages } from '../functionalities/imgCache';

export default function Caso(props) {
    let params = useParams()
    const navigate = useNavigate();
    const location = useLocation();
    const [listaCasos, setListaCasos] = useState([])
    const [dataCaso, setDataCaso] = useState([])
    const [titulo, setTitulo] = useState('')
    const [subtitulo, setSubtitulo] = useState('')
    const [featuredImage, setFeaturedImage] = useState('')

    useEffect(() => {
        getCasoConenido()
    }, [location.state])

    async function getCasoConenido() {
        if (location.state) {
            let caso = location.state.caso;            
            setDataCaso(JSON.parse(caso.blocksJSON))
            setListaCasos(location.state.lista)
            await cacheImages(location.state.lista)    
            setTitulo(caso.caso_titulo_subtitulo.titulo)
            setSubtitulo(caso.caso_titulo_subtitulo.subtitulo)
            setFeaturedImage(caso.featuredImage != null ? caso.featuredImage.node.sourceUrl : "")
            document.title = `${props.title} ${caso.caso_titulo_subtitulo.titulo}`
        } else {
            var responseValues = [];
            let titulo;
            let subtitulo;
            let featuredImage;
            await getCaso(params.proyectoNombre).then(res => {
                if (res != null) {
                    responseValues = JSON.parse(res.blocksJSON)
                    titulo = res.caso_titulo_subtitulo.titulo;
                    subtitulo = res.caso_titulo_subtitulo.subtitulo;
                    featuredImage = res.featuredImage.node.sourceUrl
                    document.title = `${props.title} ${res.caso_titulo_subtitulo.titulo}`
                } else {
                    navigate('/casos')
                }
            })       
            var responseValuesListaCasos = [];
            await getCasos().then(async res => {
                for (var i = 0; res.length > i; i++) {
                    responseValuesListaCasos = res
                }
                await cacheImages(res)
            })
            setListaCasos([...responseValuesListaCasos])     
            setDataCaso([...responseValues])
            setTitulo(titulo)
            setSubtitulo(subtitulo)
            setFeaturedImage(featuredImage)
        }
    }

    return (
        featuredImage ? <article className="post">
            <div className="post-header flex-column justify-center box-shadow" style={{ backgroundImage: `url(${featuredImage != null ? featuredImage : ''})` }}>
                {titulo && <h1 className="text-shadow">{titulo}</h1>}
                {subtitulo && <h2 className="text-shadow">{subtitulo}</h2>}
            </div>
            {dataCaso.length > 0 &&
                dataCaso.map((caso, i) => (
                    <div key={i} dangerouslySetInnerHTML={{ __html: caso.saveContent }} />
                ))
            }
            <ListaCasos amountItems="3" titulo={'Últimos casos'} dataCasos={listaCasos} />
        </article> : <div className="post-loader"><PuffLoader color={'#20222C'} loading={true} size={150} /></div>
    )
}