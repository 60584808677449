import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import trianguloProyecto from '../img/triangle-proyecto-chico.png'

export default function ListaProyectos(props) {
    const navigate = useNavigate();

    function goProyecto(i, url) {        
        navigate(url, {
            state: {
                lista: props.dataProyectos,
                proyecto: props.dataProyectos[i]
            }
        })
    }

    return (
        props.dataProyectos && <div className="lista-proyectos flex-column">
            {props.titulo && <h2>{props.titulo}</h2>}
            {/* {props.titulo && <hr />} */}
            <div className="elem-lista flex-row">
                {props.dataProyectos.slice(0, props.amountItems != null ? props.amountItems : props.dataProyectos.length).map((proyecto, i) => (
                    <button className="box-shadow" onClick={() => goProyecto(i, `/proyectos/${proyecto.slug}`)} key={proyecto.id}>
                        <div className="elemento-proyectos" style={{ backgroundImage: `url(${proyecto.featuredImage != null ? proyecto.featuredImage.node.sourceUrl : ''})` }}>
                            <img src={trianguloProyecto} alt="" />
                            {<h3>{proyecto.titulosProyectos.titulo}</h3>}
                            {/* {proyecto.proyecto_titulo_subtitulo.subtitulo && <h1>{proyecto.proyecto_titulo_subtitulo.subtitulo}</h1>} */}
                        </div>
                        {proyecto.titulosProyectos.subtitulo && <div className="descripcion-breve align-center justify-center">
                            <h4>{proyecto.titulosProyectos.subtitulo}</h4>
                        </div>}
                    </button>
                ))}
            </div>
        </div>
    )
}