import React, { useState, useEffect } from 'react';
import { getPaginaProyectos, getProyectos } from '../api/actions';
import PuffLoader from "react-spinners/PuffLoader";
import ListaProyectos from '../componentes-generales/ListaProyectos';

export default function PaginaProyectos(props) {    
    const [listaProyectos, setListaProyectos] = useState([])    
    const [titulo, setTitulo] = useState('')    
    const [featuredImage, setFeaturedImage] = useState('')

    useEffect(() => {
        getProyectosLista()
        document.title = props.title
    }, [])

    async function getProyectosLista() {                       
            var responseValuesListaProyectos = [];
            var responseValuesPaginaProyectos;
            await getProyectos().then(res => {
                for (var i = 0; res.length > i; i++) {
                    responseValuesListaProyectos = res
                }
            })
            await getPaginaProyectos().then(res => {
                responseValuesPaginaProyectos = {...res}
            })
            setListaProyectos([...responseValuesListaProyectos])                 
            setTitulo(responseValuesPaginaProyectos.title)            
            setFeaturedImage(responseValuesPaginaProyectos.featuredImage.node.sourceUrl)
    }

    return (
        listaProyectos.length > 0 ? <div className="post">
            {titulo && <div className="post-header flex-column justify-center box-shadow" style={{ backgroundImage: `url(${featuredImage != null ? featuredImage : ''})` }}>
                <h1 className="text-shadow">{titulo}</h1>
            </div>}
            <ListaProyectos dataProyectos={listaProyectos} />
        </div> : <div className="post-loader"><PuffLoader color={'#20222C'} loading={true} size={150} /></div>
    )
}