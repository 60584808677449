import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import logo from "../img/accion-logo.png";

export default function MobileMenu({
  biblioteca,
  noticias,
  donaciones,
  openMenuState,
  openCloseMenu,
}) {
  return (
    <>
      {openMenuState ? (
        <header className="flex-row align-center space-between mobile-menu">
          <button className="close-icon" onClick={() => openCloseMenu()}>
            <svg
              enable-background="new 0 0 490 490"
              version="1.1"
              viewBox="0 0 490 490"
              xmlns="http://www.w3.org/2000/svg"
            >
              <polygon points="456.85 0 245 212.56 33.149 0 0.708 32.337 212.67 245 0.708 457.68 33.149 490 245 277.44 456.85 490 489.29 457.68 277.33 245 489.29 32.337" />
            </svg>
          </button>
          <Link to="/" onClick={() => openCloseMenu()}>
            <img src={logo} alt="" />
          </Link>
          <nav>
            <div>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                to="/quienes-somos"
                onClick={() => openCloseMenu()}
              >
                Quiénes Somos
              </NavLink>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                to="/casos"
                onClick={() => openCloseMenu()}
              >
                Casos
              </NavLink>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                to="/proyectos"
                onClick={() => openCloseMenu()}
              >
                Proyectos
              </NavLink>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                to="/denuncias"
                onClick={() => openCloseMenu()}
              >
                Denuncias
              </NavLink>
              {biblioteca ? (
                <NavLink
                  className={({ isActive }) => (isActive ? "active" : "")}
                  to="/biblioteca"
                  onClick={() => openCloseMenu()}
                >
                  Biblioteca
                </NavLink>
              ) : null}
              {noticias ? (
                <NavLink
                  className={({ isActive }) => (isActive ? "active" : "")}
                  to="/noticias"
                  onClick={() => openCloseMenu()}
                >
                  Noticias
                </NavLink>
              ) : null}
              {donaciones ? (
                <NavLink
                  className={({ isActive }) => (isActive ? "active" : "")}
                  to="/donaciones"
                  onClick={() => openCloseMenu()}
                >
                  Donaciones
                </NavLink>
              ) : null}
            </div>
          </nav>
        </header>
      ) : null}
    </>
  );
}
