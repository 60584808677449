import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Outlet } from 'react-router-dom';

export default function Proyectos() {
    let params = useParams()
    return (
        <div>
            <Outlet />    
        </div>
    )
}