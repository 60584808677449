import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export default function ListaNoticias(props) {    
    const navigate = useNavigate();    

    function goProyecto(i, url) {        
        navigate(url, {
            state: {
                lista: props.dataNoticias,
                noticia: props.dataNoticias[i]
            }
        })
    }

    return (
        props.dataNoticias && <div className="lista-noticias flex-column">
            {props.titulo && <h2>{props.titulo}</h2>}
            {/* {props.titulo && <hr />} */}
            <div className="elem-lista noticia flex-row">
                {props.dataNoticias.slice(0, props.amountItems != null ? props.amountItems : props.dataNoticias.length).map((noticia, i) => {
                    const date = new Date(noticia.date)
                    return(
                        <button className="box-shadow" onClick={() => goProyecto(i, `/noticias/${noticia.slug}`)} key={noticia.id}>
                            <div className="elemento-noticias">
                                <h3>{noticia.titulosNoticias.titulo}</h3>                            
                                <p>{`${date.getDay()}/${date.getMonth()}/${date.getFullYear()}`}</p>
                                <p>
                                    {noticia.titulosNoticias.descripcionBreve.slice(0, 100) + '...'}
                                </p>
                            </div>
                        </button>
                    )
                })}
            </div>
        </div>
    )
}