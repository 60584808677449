import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { getNoticia, getNoticias } from '../api/actions';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import PuffLoader from "react-spinners/PuffLoader";
import ListaNoticias from '../componentes-generales/ListaNoticias';

export default function Noticia(props) {
    let params = useParams()
    const navigate = useNavigate();
    const location = useLocation();
    const [listaNoticias, setListaNoticias] = useState([])
    const [dataNoticia, setDataNoticia] = useState([])
    const [titulo, setTitulo] = useState('')
    const [subtitulo, setSubtitulo] = useState('')
    const [featuredImage, setFeaturedImage] = useState('')

    useEffect(() => {
        getNoticiaConenido()
    }, [location.state])

    async function getNoticiaConenido() {
        if (location.state) {
            let noticia = location.state.noticia;            
            setDataNoticia(JSON.parse(noticia.blocksJSON))
            setListaNoticias(location.state.lista)
            setTitulo(noticia.titulosNoticias.titulo)
            setSubtitulo(noticia.titulosNoticias.subtitulo)
            setFeaturedImage(noticia.featuredImage != null ? noticia.featuredImage.node.sourceUrl : "")
            document.title = `${props.title} ${noticia.titulosNoticias.titulo}`
        } else {
            var responseValues = [];
            let titulo;
            let subtitulo;
            let featuredImage;
            await getNoticia(params.noticiaNombre).then(res => {
                if (res != null) {
                    responseValues = JSON.parse(res.blocksJSON)
                    titulo = res.titulosNoticias.titulo;
                    subtitulo = res.titulosNoticias.subtitulo;
                    featuredImage = res.featuredImage.node.sourceUrl
                    document.title = `${props.title} ${res.titulosNoticias.titulo}`
                } else {
                    navigate('/noticias')
                }
            })       
            var responseValuesListaNoticias = [];
            await getNoticias().then(res => {
                for (var i = 0; res.length > i; i++) {
                    responseValuesListaNoticias = res
                }
            })
            setListaNoticias([...responseValuesListaNoticias])     
            setDataNoticia([...responseValues])
            setTitulo(titulo)
            setSubtitulo(subtitulo)
            setFeaturedImage(featuredImage)
        }
    }

    return (
        titulo ? <article className="post">
            <div className="post-header flex-column justify-center box-shadow" style={{ backgroundImage: `url(${featuredImage != null ? featuredImage : ''})` }}>
                {titulo && <h1 className="text-shadow">{titulo}</h1>}
                {subtitulo && <h2 className="text-shadow">{subtitulo}</h2>}
            </div>
            {dataNoticia.length > 0 &&
                dataNoticia.map((caso, i) => (
                    <div key={i} dangerouslySetInnerHTML={{ __html: caso.saveContent }} />
                ))
            }
            <ListaNoticias amountItems="3" titulo={'Últimas Noticias'} dataNoticias={listaNoticias} />
        </article> : <div className="post-loader"><PuffLoader color={'#20222C'} loading={true} size={150} /></div>
    )
}