import React from "react";

export default function FormDenunciasArtistas() {
  return (
    <div className="proteccion-artistas">
      <a href="https://form.jotform.com/PAR_LAC/PARESP">
        Ir a Formulario Protección de Artistas en Riesgo
      </a>
    </div>
  );
}
