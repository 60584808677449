import React, { useState, useEffect } from "react";
import { getCasos, getHome, getNoticias, getProyectos } from "../api/actions";
import PuffLoader from "react-spinners/PuffLoader";
import ListaCasos from "../componentes-generales/ListaCasos";
import ListaProyectos from "../componentes-generales/ListaProyectos";
import ListaNoticias from "../componentes-generales/ListaNoticias";
import { postForm, getTwitter } from "../api/services";
import { cacheImages } from "../functionalities/imgCache";

export default function Home(props) {
  const [listaCasos, setListaCasos] = useState([]);
  const [listaProyectos, setListaProyectos] = useState([]);
  const [listaNoticias, setListaNoticias] = useState([]);
  const [homeIntro, setHomeIntro] = useState("");

  useEffect(() => {
    loadContent();
    document.title = props.title;
  }, []);

  async function loadContent() {
    await getCasosConenido();
    await getProyectosConenido();
    await getNoticiasConenido();
    await getHomeContent();
  }

  async function getCasosConenido() {
    let responseValues = [];
    let imgs = [];
    await getCasos().then((res) => {
      responseValues = res;
    });
    await cacheImages(responseValues);
    setListaCasos([...responseValues]);
  }
  async function getProyectosConenido() {
    let responseValues = [];
    await getProyectos().then((res) => {
      responseValues = res;
    });
    await cacheImages(responseValues);
    setListaProyectos([...responseValues]);
  }
  async function getNoticiasConenido() {
    let responseValues = [];
    await getNoticias().then((res) => {
      responseValues = res;
    });
    await cacheImages(responseValues);
    setListaNoticias([...responseValues]);
  }

  async function getHomeContent() {
    let response;
    await getHome().then((res) => {
      response = res;
    });
    setHomeIntro(response);
  }

  return (
    <div className="home flex-row">
      <section className="flex-column home-content">
        {listaCasos.length > 0 &&
        listaProyectos.length > 0 &&
        listaNoticias.length > 0 ? (
          <div>
            <div
              className="texto-intro"
              dangerouslySetInnerHTML={{ __html: homeIntro }}
            />
            <ListaCasos
              amountItems="3"
              titulo={"Últimos casos"}
              dataCasos={listaCasos}
            />
            <ListaProyectos
              amountItems="3"
              titulo={"Últimos proyectos"}
              dataProyectos={listaProyectos}
            />
            {/* <ListaNoticias amountItems="3" titulo={'Últimas noticias'} dataNoticias={listaNoticias} /> */}
          </div>
        ) : (
          <div className="loader">
            <PuffLoader color={"#20222C"} loading={true} size={150} />
          </div>
        )}
      </section>
      <TwitterContainer />
    </div>
  );
}

function TwitterContainer() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    document.getElementsByClassName("twitter-embed")[0].appendChild(script);
  }, []);

  return (
    <section className="twitterContainer">
      <div className="twitter-embed">
        <a
          className="twitter-timeline"
          data-theme="light"
          data-tweet-limit="3"
          data-chrome="noheader nofooter noborders"
          href="https://twitter.com/OngAccionConst"
        >
          {/* Tweets de Acción Constitucional */}
        </a>
      </div>
    </section>
  );
}
