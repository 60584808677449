import React from "react";
import { NavLink, Link } from "react-router-dom";
import logo from "../img/accion-logo.png";

export default function Menu({
  biblioteca,
  noticias,
  donaciones,
  openCloseMenu,
}) {
  return (
    <header className="flex-row align-center space-between">
      <Link to="/">
        <img src={logo} alt="" />
      </Link>
      <button className="burger-menu" onClick={() => openCloseMenu()}>
        <svg
          width="30px"
          height="30px"
          viewBox="0 0 16 16"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="16" height="16" id="icon-bound" fill="none" />
          <path d="M1,9h14V7H1V9z M1,14h14v-2H1V14z M1,2v2h14V2H1z" />
        </svg>
      </button>
      <nav>
        <NavLink
          className={({ isActive }) => (isActive ? "active" : "")}
          to="/quienes-somos"
        >
          Quiénes Somos
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? "active" : "")}
          to="/casos"
        >
          Casos
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? "active" : "")}
          to="/proyectos"
        >
          Proyectos
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? "active" : "")}
          to="/denuncias"
        >
          Denuncias
        </NavLink>
        {biblioteca ? (
          <NavLink
            className={({ isActive }) => (isActive ? "active" : "")}
            to="/biblioteca"
          >
            Biblioteca
          </NavLink>
        ) : null}
        {noticias ? (
          <NavLink
            className={({ isActive }) => (isActive ? "active" : "")}
            to="/noticias"
          >
            Noticias
          </NavLink>
        ) : null}
        {donaciones ? (
          <NavLink
            className={({ isActive }) => (isActive ? "active" : "")}
            to="/donaciones"
          >
            Donaciones
          </NavLink>
        ) : null}
      </nav>
    </header>
  );
}
