import React, { useState, useEffect } from "react";
import { getPoliticaPrivacidad } from "../api/actions";
import PuffLoader from "react-spinners/PuffLoader";
import { cacheImages } from "../functionalities/imgCache";

export default function PoliticasPrivacidad(props) {
  const [contenidoBlocks, setContenidoBlocks] = useState([]);
  const [titulo, setTitulo] = useState("");
  const [featuredImage, setFeaturedImage] = useState("");

  useEffect(() => {
    getContenidosBlocks();
    document.title = props.title;
  }, [props]);

  async function getContenidosBlocks() {
    let responseValues = [];
    let titulo = "";
    let featuredImage = "";
    let localArray = [];
    await getPoliticaPrivacidad().then((res) => {
      localArray.push(res);
      responseValues = JSON.parse(res.blocksJSON);
      titulo = res.title;
      featuredImage = res.featuredImage.node.sourceUrl;
    });
    await cacheImages(localArray);
    setContenidoBlocks([...responseValues]);
    setTitulo(titulo);
    setFeaturedImage(featuredImage);
  }

  return contenidoBlocks.length > 0 ? (
    <section className="post">
      <div
        className="post-header flex-column justify-center box-shadow"
        style={{
          backgroundImage: `url(${featuredImage != null ? featuredImage : ""})`,
        }}
      >
        {titulo && <h1 className="text-shadow">{titulo}</h1>}
      </div>
      {contenidoBlocks.length > 0 &&
        contenidoBlocks.map((caso, i) => (
          <div key={i} dangerouslySetInnerHTML={{ __html: caso.saveContent }} />
        ))}
    </section>
  ) : (
    <div className="post-loader">
      <PuffLoader color={"#20222C"} loading={true} size={150} />
    </div>
  );
}
