import axios from 'axios';

export const webService = {
    post
};

var accion = 'https://accion.testdesign.website/graphql'
var local = 'http://localhost/accionconstitucional/graphql'

const getData = (payload) => {
    const formData = new FormData();
    Object.entries(payload).forEach(([key, value]) => {
        formData.append(key, value);
    });
    return formData;
}

async function post(query) {
    const options = {
        method: 'POST',
        url: accion,
        headers: {
            'content-type': 'application/json',
            /* 'x-rapidapi-host': 'geodb-cities-graphql.p.rapidapi.com',
            'x-rapidapi-key': process.env.REACT_PUBLIC_RAPIDAPI_KEY */
        },
        data: {
            query: query
        }
    }
    var response;
    await axios
        .request(options)
        .then(function (res) {            
            response = res.data.data
        })
        .catch(function (error) {
            console.error(error);
        });
    return response
}

export async function postForm(data) {
    let headers = {         
        /* 'Content-Type': 'application/x-www-form-urlencoded', */"Content-Type": "multipart/form-data"
     };
     return await axios.post('https://accion.testdesign.website/wp-json/contact-form-7/v1/contact-forms/139/feedback', getData(data), { headers })
    .then(function (response) {              
        return response.data;
    })
    .catch(function (error) {
        throw error;
    });
}

