import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../img/accion-logo.png";
import footerBackground from "../img/addon-footer.png";

export default function Footer() {
  return (
    <footer className="flex-row align-center justify-center">
      <img alt="" className="footer-background" src={footerBackground}></img>
      <div className="flex-column align-center justify-center">
        <img alt="" src={logo} />
        <p>
          <a href="mailto:contacto@accionconstitucional.org">Contáctanos</a> |{" "}
          {/* <a href="/politicas-privacidad">Políticas de privacidad</a> */}
          <NavLink
            className={({ isActive }) => (isActive ? "active" : "")}
            to="/politicas-privacidad"
          >
            Políticas de privacidad
          </NavLink>
        </p>
      </div>
    </footer>
  );
}
