import React, { useState, useEffect } from 'react';
import { getPaginaCasos, getCasos } from '../api/actions';
import PuffLoader from "react-spinners/PuffLoader";
import ListaCasos from '../componentes-generales/ListaCasos';

export default function PaginaCasos(props) {    
    const [listaCasos, setListaCasos] = useState([])    
    const [titulo, setTitulo] = useState('')    
    const [featuredImage, setFeaturedImage] = useState('')

    useEffect(() => {
        getCasosLista()
        document.title = props.title
    }, [])

    async function getCasosLista() {                       
            var responseValuesListaCasos = [];
            var responseValuesPaginaCasos;
            await getCasos().then(res => {
                for (var i = 0; res.length > i; i++) {
                    responseValuesListaCasos = res
                }
            })
            await getPaginaCasos().then(res => {
                responseValuesPaginaCasos = {...res}
            })
            setListaCasos([...responseValuesListaCasos])                 
            setTitulo(responseValuesPaginaCasos.title)            
            setFeaturedImage(responseValuesPaginaCasos.featuredImage.node.sourceUrl)
    }

    return (
        listaCasos.length > 0 ? <div className="post">
            {titulo && <div className="post-header flex-column justify-center box-shadow" style={{ backgroundImage: `url(${featuredImage != null ? featuredImage : ''})` }}>
                <h1 className="text-shadow">{titulo}</h1>
            </div>}
            <ListaCasos dataCasos={listaCasos} />
        </div> : <div className="post-loader"><PuffLoader color={'#20222C'} loading={true} size={150} /></div>
    )
}