import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter, Routes, Navigate, Route } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import Nosotros from "./paginas/Nosotros";
import Casos from "./paginas/Casos";
import Proyectos from "./paginas/Proyectos";
import Denuncias from "./paginas/Denuncias";
import Biblioteca from "./paginas/Biblioteca";
import Noticias from "./paginas/Noticias";
import Donaciones from "./paginas/Donaciones";
import NothingHere from "./paginas/NothingHere";
import Proyecto from "./paginas/Proyecto";
import Home from "./paginas/Home";
import Caso from "./paginas/Caso";
import Noticia from "./paginas/Noticia";
import QuienesSomos from "./paginas/QuienesSomos";
import QueHacemos from "./paginas/QueHacemos";
import PaginaProyectos from "./paginas/PaginaProyectos";
import PaginaNoticias from "./paginas/PaginaNoticias";
import PaginaCasos from "./paginas/PaginaCasos";
import PoliticasPrivacidad from "./paginas/PoliticasPrivacidad";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route
            path="/"
            element={<Home title="Acción constitucional | Home" />}
          />
          <Route
            path="quienes-somos"
            element={
              <QuienesSomos title="Acción constitucional | Quienes somos" />
            }
          />
          {/* <Route path="nosotros" element={<Nosotros />}>
            <Route path="quienes-somos" element={<QuienesSomos title="Acción constitucional | Quienes somos" />} />
            <Route path="que-hacemos" element={<QueHacemos />} />
          </Route>  */}
          <Route path="proyectos" element={<Proyectos />}>
            <Route
              index
              element={
                <PaginaProyectos title="Acción constitucional | Proyectos" />
              }
            />
            <Route
              path=":proyectoNombre"
              element={<Proyecto title="Acción constitucional |" />}
            />
          </Route>
          <Route path="casos" element={<Casos />}>
            <Route
              index
              element={<PaginaCasos title="Acción constitucional | Casos" />}
            />
            <Route
              path=":proyectoNombre"
              element={<Caso title="Acción constitucional |" />}
            />
          </Route>
          <Route path="noticias" element={<Noticias />}>
            <Route index element={<PaginaNoticias />} />
            <Route
              path=":proyectoNombre"
              element={<Noticia title="Acción constitucional |" />}
            />
          </Route>
          <Route
            path="denuncias"
            element={<Denuncias title="Acción constitucional | Denuncias" />}
          />
          <Route path="biblioteca" element={<Biblioteca />} />
          <Route path="donaciones" element={<Donaciones />} />
          <Route
            path="politicas-privacidad"
            element={
              <PoliticasPrivacidad title="Acción constitucional | Políticas de Privacidad" />
            }
          />
          <Route path="*" element={<NothingHere />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
