import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { getProyecto, getProyectos } from '../api/actions';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import PuffLoader from "react-spinners/PuffLoader";
import ListaProyectos from '../componentes-generales/ListaProyectos';
import { cacheImages } from '../functionalities/imgCache';

export default function Proyecto(props) {
    let params = useParams()
    const navigate = useNavigate();
    const location = useLocation();
    const [listaProyectos, setListaProyectos] = useState([])
    const [dataProyecto, setDataProyecto] = useState([])
    const [titulo, setTitulo] = useState('')
    const [subtitulo, setSubtitulo] = useState('')
    const [featuredImage, setFeaturedImage] = useState('')

    useEffect(() => {
        getProyectoConenido()        
    }, [location.state])

    async function getProyectoConenido() {
        if (location.state) {            
            let proyecto = location.state.proyecto;                                                              
            await cacheImages(location.state.lista)    
            setDataProyecto(JSON.parse(proyecto.blocksJSON))
            setListaProyectos(location.state.lista)
            setTitulo(proyecto.titulosProyectos.titulo)
            setSubtitulo(proyecto.titulosProyectos.subtitulo)
            setFeaturedImage(proyecto.featuredImage != null ? proyecto.featuredImage.node.sourceUrl : "")            
            document.title = `${props.title} ${proyecto.titulosProyectos.titulo}`
        } else {
            var responseValues = [];
            let titulo;
            let subtitulo;
            let featuredImage;
            let response;
            await getProyecto(params.proyectoNombre).then(async res => {
                if (res != null) {                                    
                    responseValues = JSON.parse(res.blocksJSON)
                    titulo = res.titulosProyectos.titulo;
                    subtitulo = res.titulosProyectos.subtitulo;
                    featuredImage = res.featuredImage.node.sourceUrl
                    document.title = `${props.title} ${res.titulosProyectos.titulo}`                                 
                } else {
                    navigate('/proyectos')
                }
            })       
            var responseValuesListaProyectos = [];
            await getProyectos().then(async res => {
                for (var i = 0; res.length > i; i++) {
                    responseValuesListaProyectos = res
                }                                  
                await cacheImages(res)
            })            
            setListaProyectos([...responseValuesListaProyectos])     
            setDataProyecto([...responseValues])
            setTitulo(titulo)
            setSubtitulo(subtitulo)
            setFeaturedImage(featuredImage)
        }
    }

    return (
        titulo ? <article className="post">
            <div className="post-header flex-column justify-center box-shadow" style={{ backgroundImage: `url(${featuredImage != null ? featuredImage : ''})` }}>
                {titulo && <h1 className="text-shadow">{titulo}</h1>}
                {subtitulo && <h2 className="text-shadow">{subtitulo}</h2>}
            </div>
            {dataProyecto.length > 0 &&
                dataProyecto.map((caso, i) => (
                    <div key={i} dangerouslySetInnerHTML={{ __html: caso.saveContent }} />
                ))
            }
            <ListaProyectos amountItems="3" titulo={'Últimos Proyectos'} dataProyectos={listaProyectos} />
        </article> : <div className="post-loader"><PuffLoader color={'#20222C'} loading={true} size={150} /></div>
    )
}