import React, { useState, useEffect } from 'react';
import { getPaginaNoticias, getNoticias } from '../api/actions';
import PuffLoader from "react-spinners/PuffLoader";
import ListaNoticias from '../componentes-generales/ListaNoticias';

export default function PaginaNoticias(props) {    
    const [listaNoticias, setListaNoticias] = useState([])    
    const [titulo, setTitulo] = useState('')    
    const [featuredImage, setFeaturedImage] = useState('')

    useEffect(() => {
        getNoticiasLista()
    }, [])

    async function getNoticiasLista() {                       
            var responseValuesListaNoticias = [];
            var responseValuesPaginaNoticias;
            await getNoticias().then(res => {
                for (var i = 0; res.length > i; i++) {
                    responseValuesListaNoticias = res
                }
            })
            await getPaginaNoticias().then(res => {
                responseValuesPaginaNoticias = {...res}
            })
            setListaNoticias([...responseValuesListaNoticias])                 
            setTitulo(responseValuesPaginaNoticias.title)            
            setFeaturedImage(responseValuesPaginaNoticias.featuredImage.node.sourceUrl)
    }

    return (
        listaNoticias.length > 0 ? <div className="post">
            {titulo && <div className="post-header flex-column justify-center box-shadow" style={{ backgroundImage: `url(${featuredImage != null ? featuredImage : ''})` }}>
                <h1 className="text-shadow">{titulo}</h1>
            </div>}
            <ListaNoticias dataNoticias={listaNoticias} />
        </div> : <div className="post-loader"><PuffLoader color={'#20222C'} loading={true} size={150} /></div>
    )
}